<template>
  <v-card class="shadow-0  bordered facility_widget"  @click="viewFacility(id)" >
    <v-card-text >
      <v-row justify="center" class="border-bottom " dense>
        <v-col class="text-center d-flex justify-space-between">
          <span v-if="capacity>=1"/>
          <TimeIcon v-else/>
          <p class="text-xl black-text font-bold">{{ name }}</p>
          <div class="d-flex justify-space-between">
            <LightningBoltIcon  v-if="isConfigurationEnableBooking == 1" :class="is_public ? '':'fill-red'"/>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-3" x-small @click.stop="duplicateFacility(id)" icon  v-on="on">
                  <CopyIcon/>
                </v-btn>
              </template>
              <span>Duplicate this facility</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <v-row  dense class="mt-4 align-center">
        <v-col md="5">
          <simple-image
              :image="image"
              :defaultImage="'ground'"
              style="border-radius: 8px"
          ></simple-image>
        </v-col>

        <v-col  md="7">
          <div class="member_details pl-2">
            <div class="d-flex justify-space-between  stats_col">
              <p class="text-base font-normal text-dark-gray ma-0 pa-0">Surface:</p>
              <p class="text-base font-normal text-dark-gray ma-0 pa-0">Configuration:</p>

            </div>
            <div class="d-flex justify-space-between stats_col ">
              <p class="text-base black-text font-medium"> {{ getGameFormation() || "--" }}</p>
              <p class="text-base blue-text font-medium ">  {{ facility_surface != null ? facility_surface.name : "--" }}</p>
            </div>

<!--            <div class="d-flex justify-space-between stats_col ">-->
<!--              <p class="text-base font-normal text-dark-gray">Booking Type:</p>-->
<!--              <p class="text-base black-text font-medium"> {{ capacity>=1 ? "Capacity " :"Time " }}</p>-->
<!--            </div>-->

<!--            <div class="d-flex justify-space-between stats_col ">-->
<!--              <p class="text-base font-normal text-dark-gray">Is Public:</p>-->
<!--              <p class="text-base black-text font-medium"> {{ is_public ? "Yes " :"No " }}</p>-->
<!--            </div>-->


          </div>
          <v-simple-table
              dense
              class=" dependencies "
              height="100"
              fixed-header

          >
            <template v-slot:default>
              <thead class="" >
                <tr class="opacity-70 tr-neon ">
                  <th class="" style="font-size: 10px !important;">Dependency</th>
                  <th class="" style="font-size: 10px !important;">Service</th>
                </tr>
              </thead>
              <tbody               v-if="child_facilities.length || parent_facilities.length"
              >
              <tr v-for="item in child_facilities" :key="`c_${item.id}`">
                <td class="text-xxs">{{ item.name }}</td>
                <td class="text-xxs">
                  {{ item.facility_services[0].venue_service.service.name }}
                </td>
              </tr>
              <tr v-for="item in parent_facilities" :key="`p_${item.id}`">
                <td class="text-xxs"> {{ item.name }}</td>
                <td class="text-xxs">
                  {{ item.facility_services[0].venue_service.service.name }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
                <tr >
                  <td colspan="2" rowspan="2" class="text-center nodepend">No Dependencies</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SimpleImage from "@/components/Image/SimpleImage.vue";
import LightningBoltIcon from "@/assets/images/facilities/lightning-bolt.svg";
import TimeIcon from "@/assets/images/facilities/time.svg";
import CopyIcon from "@/assets/images/misc/copy-bg-icon.svg";

export default {
  components: {SimpleImage,LightningBoltIcon,CopyIcon,TimeIcon},
  props: {
    name: { type: String, default: "" },
    type: { type: String, default: "" },
    id: { type: Number },
    image: { type: String },
    facility_surface: { type: Object, default: () => {} },
    game_formations: { type: Array, default: () => [] },
    capacity: { type: Number, default: 0 },
    venueServiceId: { type: Number },
    child_facilities: { type: Array, default: () => [] },
    parent_facilities: { type: Array, default: () => [] },
    is_public: { type: Number, default: 0 },
    isConfigurationEnableBooking: { type: Number, default: 0 },
    isGameFormationEnabled: { type: Number, default: 0 },
    isSurfaceEnabled:{type: Number, default: 0},
  },
  methods: {
    edit() {
      this.$router.push({
        name: "FacilityForm",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              facility_id: this.id,
            })
          ),
        },
      });
    },
    duplicateFacility(id){
      console.log('clicked',id);
      this.showLoader("Duplicating...")
      this.$http
          .post(`venues/facilities/duplicate`, {facility_id:id})
          .then((response) => {
            if (response.status === 200 && response.data.status) {
              this.showSuccess(response.data.message);
              this.$emit('reload');
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader()
          });

    },
    viewFacility() {
      this.$router.push({
        name: "ViewFacility",
        params: { data: btoa(this.id) },
      });
    },
    deleteGround(id) {
      this.$emit("remove", id);
    },
    getGameFormation() {
      if (this.game_formations.length > 0) {
        return this.game_formations.map((item) => item.name).join(", ");
      }
      return "--";
    },
  },
};
</script>

<style scoped>

.ground_det_table {
  color: rgb(235, 235, 235);
  width: 100%;
}
.ground_det_table tr td {
  padding: 3px;
}

.ground_det_table td:first-child {
  color: #b0ada8;
}
.ground_card {
  min-height: 200px;
}


.ground_head {
  text-align: center;
  border-radius: 12px;
  width: 310px;
  margin: auto;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
  max-height: 93px;
  width: 100%;
}

.view_more {
  width: 10%;
}

.pricing_option .titledet {
  width: 40%;
}

.pricing_option .timedet {
  width: 30%;
}

.pricing_option .price {
  width: 20%;
}

.g_subheading {
  width: 100%;
  text-align: left;
  padding: 0 0 5px 7px;
  color: #fff;
}

.price_option_container {
  width: 100%;
}

.ground_Img {
  margin: 10px;
  width: 90%;
  border-radius: 4px;
}
.dependencies {
  color: black;
  background-color: #F8FAFB !important;
  thead{
    border: none !important;
    border-bottom: none !important;
    th{
      font-size: 10px !important;
    }
  }
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
.dependencies thead th {
  background-color: #F8FAFB !important;
  color: black !important;
  font-style: normal !important;
  font-weight: 500;
  line-height: normal;;
  box-shadow: inset 0 -1px 0 white !important;
}


.dependencies tbody td {
  color: #333;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.facility_widget{
  border-radius: 8px;

}

.facility_widget:hover{
  border: 1px solid rgba(17, 42, 70, 0.5);
  box-shadow: 0 8px 24px 0  rgba(70, 76, 136, 0.20) !important;
}

.fill-red{
  fill: red !important;
}

.nodepend{
  color: #A6A6A6;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
