<template>
  <v-container fluid>
    <FacilitiesTabs
        :venue-service-id="venueServiceId"
        :venue-services="venueServices"
        @serviceChange="serviceChange"
        @update:venueServiceId="(value)=>venueServiceId = value"
        :configurations="configurations"
        :show-add-facility="true"
    />

    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-row class="mt-8" dense>
      <v-col v-for="(facility, index) in facilities" :key="index" lg="4" md="6" sm="12" xl="3">
        <facility-widget
            :is-configuration-enable-booking="isConfigurationEnableBooking"
            :isGameFormationEnabled="configurations.is_game_formation_enabled"
            :isSurfaceEnabled="configurations.is_surface_enabled"
            :venue-service-id="venueServiceId"
            v-bind="facility"
            @reload="getFacilities"
            @remove="deleteFacility"
        />
      </v-col>
    </v-row>
    <h3 v-if="facilities.length == 0" class="text-center mt-12">
      No facility present
    </h3>

    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>

    <timing-templates
        v-if="timingDialog"
        :showTimingTemplate="timingDialog"
        :venueServiceId="venueServiceId"
        @close="timingDialog = false"
    />
    <!-- <rental-products
      :venueServiceId="hirePricingTrigger"
      @close="hirePricingTrigger = null"
    /> -->
  </v-container>
</template>

<script>
import FacilityWidget from "@/components/Facility/FacilityWidget";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import TimingTemplates from "@/components/Facility/TimingTemplates.vue";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    FacilityWidget,
    ConfirmModel,
    TimingTemplates,
  },

  data: () => ({
    facilities: [],
    confirmModel: {},
    venueServiceId: 0,
    timingDialog: false,
    hirePricingTrigger: null,
    configurations: {},
    isConfigurationEnableBooking: 0,
  }),

  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
      //console.log(this.isConfigurationEnableBooking);
    }
  },

  computed: {
    venueServices() {
      return this.$store.getters.getSportsService.filter((service) => service.name != "POS");
    },
  },

  methods: {
    serviceChange() {
      this.getFacilities();
      this.initializeFacilityServices();
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        if (this.$store.getters.getSportsService.length) {
          this.venueServiceId =
              this.$store.getters.getSportsService[0].venue_service_id;
        }
      }
      setTimeout(() => {
        this.initializeFacilityServices();
        this.getFacilities();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
            .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
            .then((response) => {
              if (response.status == 200) {
                let data = this.$store.getters.getConfigurationByVenueServiceId(
                    this.venueServiceId
                );
                this.configurations = data;
                this.isConfigurationEnableBooking = data.is_public;
                this.$forceUpdate();
              }
            });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
            this.venueServiceId
        );
        this.configurations = data;
        this.isConfigurationEnableBooking = data.is_public;
      }
    },
    getFacilities() {
      if (this.venueServiceId == 0) {
        this.showInfo("Please add service");
        return;
      }
      this.showLoader("Loading Facilities");
      this.facilities = [];
      this.$http
          .get(
              `venues/facilities?type=ground&venue_service_id=${this.venueServiceId}`
          )
          .then((response) => {
            this.hideLoader();
            if (
                response.status == 200 &&
                response.data.status == true &&
                response.data.data.length
            ) {
              this.facilities = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    deleteFacility(id) {
      this.confirmModel = {
        id: id,
        title: `Do you want to delete this facility?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    confirmActions(data) {
      this.showLoader("Delete Facilities");
      this.$http
          .delete(`venues/facilities/${data.id}`)
          .then(() => {
            this.hideLoader();
            this.getFacilities();
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
  },
};
</script>
<style scoped>


</style>
